import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, ListItemAvatar, ListItemButton, ListItemText, Typography, } from '@mui/material';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';
import { useLocales } from '../../../locales';
import { markOneAsSeen, } from '../../../redux/slices/notifications/notificationSlice';
import { markAsRead } from '../../../redux/slices/notifications/notificationThunk';
import { dispatch } from '../../../redux/store';
import { fToNow } from '../../../utils/formatTime';
import MAIL from '../../../assets/icons/notification/ic_mail.svg';
import ENVELOPE from '../../../assets/icons/notification/ic_envelope.svg';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useNavigate } from 'react-router';
import { getCommandById } from '../../../redux/slices/command/commandThunk';
export function OneNotificationItem({ notification, handleClosePopover, }) {
    const { translate } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const handleMarkAsRead = async () => {
        dispatch(markAsRead({ id: notification._id })).then((res) => {
            if (res?.payload?.statusCode >= 400) {
                enqueueSnackbar(res?.payload?.message, { variant: 'error' });
            }
            else {
                dispatch(markOneAsSeen({ id: notification._id }));
                enqueueSnackbar(`${translate(`marked_one_seen`)}`);
            }
        });
    };
    const navigate = useNavigate();
    return notification?.entityName !== 'Traitement' ? (_jsxs(ListItemButton, { sx: {
            py: 1.5,
            px: 2.5,
            mt: '1px',
            ...(!notification.seen && {
                bgcolor: 'primary.light',
            }),
            borderRadius: '12px',
            margin: '5px',
        }, onClick: () => {
            if (notification?.entityName === 'Command') {
                handleClosePopover();
                dispatch(getCommandById({ id: notification?.entityId }));
                navigate(PATH_DASHBOARD.order.replace(':id', notification?.entityId));
            }
            if (!notification?.seen)
                handleMarkAsRead();
        }, children: [_jsx(ListItemAvatar, { children: notification.seen ? (_jsx("img", { alt: notification?.sender?.name, src: MAIL })) : (_jsx("img", { alt: notification?.sender?.name, src: ENVELOPE, width: 25 })) }), _jsx(ListItemText, { secondary: _jsxs(Stack, { direction: "row", sx: {
                        mt: 0.5,
                        typography: 'caption',
                        color: 'text.disabled',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        flexWrap: 'wrap',
                        gap: '15px',
                    }, children: [_jsxs(Stack, { direction: "column", sx: {
                                mt: 0.5,
                                typography: 'caption',
                                color: 'text.disabled',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                            }, children: [_jsx(Typography, { color: 'gray', sx: { fontWeight: 'bold' }, children: notification?.message }), _jsxs(Box, { sx: {
                                        mt: 0.5,
                                        typography: 'caption',
                                        color: 'text.disabled',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }, children: [_jsx(Iconify, { icon: "eva:clock-fill", width: 16, sx: { mr: 0.5 } }), _jsx(Typography, { variant: "caption", children: fToNow(notification.createdAt) })] })] }), _jsx(Box, { sx: {
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'start',
                                flexDirection: 'column',
                                justifyContent: 'start',
                            }, children: notification?.entityName === 'Command' && (_jsx(Button, { variant: "contained", color: "info", onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (notification?.entityName === 'Command') {
                                        handleClosePopover();
                                        dispatch(getCommandById({ id: notification?.entityId }));
                                        if (!notification?.seen)
                                            handleMarkAsRead();
                                        navigate(PATH_DASHBOARD.consult.replace(':id', notification?.entityId));
                                    }
                                }, sx: { fontSize: 12, alignSelf: 'start' }, children: "Consulter" })) })] }) })] })) : null;
}
